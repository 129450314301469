import { frontendURL } from '../../../helper/URLHelper';
const WebPhone = () => import('./WebPhone.vue');
const Layout = () => import('./Layout.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/web_phone'),
      component: Layout,
      roles: ['administrator', 'agent'],
      children: [
        {
          path: '',
          name: 'web_phone_home',
          component: WebPhone,
          roles: ['administrator', 'agent'],
        },
      ],
    },
  ],
};
