import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import CampaignsAPI from '../../api/campaigns';
import Vue from 'vue';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
  },
  meta: {
    count: 0,
    currentPage: 1,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getCampaigns: _state => {
    return _state.records;
  },
  getMeta: _state => {
    return _state.meta;
  },
};

export const actions = {
  get: async function getCampaigns({ commit }, { pageNumber }) {
    commit(types.SET_ONEOFF_CAMPAIGN_UI_FLAG, { isFetching: true });
    try {
      const response = await CampaignsAPI.getOneOffCampaigns({ pageNumber });
      commit(types.SET_ONEOFF_CAMPAIGNS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_ONEOFF_CAMPAIGN_UI_FLAG, { isFetching: false });
    }
  },
  create: async function createCampaign({ commit }, campaignObj) {
    commit(types.SET_ONEOFF_CAMPAIGN_UI_FLAG, { isCreating: true });
    try {
      const response = await CampaignsAPI.create(campaignObj);
      commit(types.ADD_ONEOFF_CAMPAIGN, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_ONEOFF_CAMPAIGN_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_ONEOFF_CAMPAIGN_UI_FLAG, { isUpdating: true });
    try {
      const response = await CampaignsAPI.update(id, updateObj);
      commit(types.EDIT_ONEOFF_CAMPAIGN, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_ONEOFF_CAMPAIGN_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_ONEOFF_CAMPAIGN_UI_FLAG, { isDeleting: true });
    try {
      await CampaignsAPI.delete(id);
      commit(types.DELETE_ONEOFF_CAMPAIGN, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_ONEOFF_CAMPAIGN_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_ONEOFF_CAMPAIGN_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.ADD_ONEOFF_CAMPAIGN]: (state, data) => {
    state.records.push(data);
    state.meta.count += 1;
  },
  [types.SET_ONEOFF_CAMPAIGNS]: ($state, data) => {
    $state.records = data.payload;
    Vue.set($state.meta, 'count', data.meta.count);
    Vue.set($state.meta, 'currentPage', data.meta.current_page);
  },
  [types.EDIT_ONEOFF_CAMPAIGN]: MutationHelpers.update,
  [types.DELETE_ONEOFF_CAMPAIGN]: ($state, id) => {
    $state.records = $state.records.filter(record => record.id !== id);
    $state.meta.count -= 1;
  }
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
