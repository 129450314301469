<template>
  <div class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%]">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.BEEM.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.BEEM.DESC')"
    />
    <form class="mx-0 flex flex-wrap" @submit.prevent="createChannel()">
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.inboxName.$error }">
          {{ $t('INBOX_MGMT.ADD.BEEM.INBOX_NAME.LABEL') }}
          <input
            v-model.trim="inboxName"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.BEEM.INBOX_NAME.PLACEHOLDER')"
            @blur="$v.inboxName.$touch"
          />
          <span v-if="$v.inboxName.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.BEEM.INBOX_NAME.ERROR') }}
          </span>
        </label>
      </div>

      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.appId.$error }">
          {{ $t('INBOX_MGMT.ADD.BEEM.APP_ID.LABEL') }}
          <input
            v-model.trim="appId"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.BEEM.APP_ID.PLACEHOLDER')
            "
            @blur="$v.appId.$touch"
          />
          <span v-if="$v.appId.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.BEEM.APP_ID.ERROR') }}
          </span>
        </label>
      </div>

      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.apiKey.$error }">
          <span>
            {{ $t('INBOX_MGMT.ADD.BEEM.API_KEY.LABEL') }}
          </span>
          <input
            v-model.trim="apiKey"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.BEEM.API_KEY.PLACEHOLDER')"
            @blur="$v.apiKey.$touch"
          />
          <span v-if="$v.apiKey.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.BEEM.API_KEY.ERROR') }}
          </span>
        </label>
      </div>

      <div class="w-full">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.BEEM.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import PageHeader from '../../SettingsSubPageHeader.vue';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';

export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin],
  data() {
    return {
      inboxName: '',
      appId: '',
      apiKey: '',
    };
  },
  computed: {
    ...mapGetters({ uiFlags: 'inboxes/getUIFlags' }),
  },
  validations: {
    inboxName: { required },
    appId: { required },
    apiKey: { required },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const beemChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: this.inboxName,
            channel: {
              type: 'beem',
              app_id: this.appId,
              config: {
                api_key: this.apiKey,
              },
            },
          }
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: beemChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.BEEM.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>
