import ApiClient from './ApiClient';

class CampaignsAPI extends ApiClient {
  constructor() {
    super('campaigns', { accountScoped: true });
  }

  getOneOffCampaigns({ pageNumber = 1 }) {
    return axios.get(`${this.url}/one_off?page=${pageNumber}`);
  }
}

export default new CampaignsAPI();
